//$word-form-background: #5887AA;
$word-form-background: #fff;
$word-form-heading: #666;
$word-form-heading-size: 16pt;

$site-green: #008800;
$site-red: #de3c4b;

$btn-font-size: 14pt;
$btn-background: #45425a;
$btn-color: white;
$btn-save-background: #09f;
$btn-cancel-background: #09f;

$maxwidth: 400px;
$header-height: 50px;
$spotlight-width: 380px;
$spotlight-height: 200px;
.word-form-container {
	backdrop-filter: blur(2px);
	position: fixed;
	top: 0;
	z-index: 1000;
	width: 100vw;
/*	height: 100%;*/

	display: flex;
	justify-content: center;

	.word-form-wrapper {
		position: relative;
		top: 50px;
		min-width: $spotlight-width;
		min-height: $spotlight-height;
		box-shadow: #ccc 2px 2px 10px;

		.word-form {
			border: 2px solid #ccc;
/*			border-radius: 10px 10px 0 0;*/
			background-color: $word-form-background;
/*			width: 300px;*/

			.header {
				border-radius: 5px;
				font-size: 14pt;
				font-weight: bold;
				text-align: center;
				min-height: 20px;
				padding: 5px;
				color: #ddd;
			}

			.field-wrapper {
				padding: 10px;

				.word {
					color: $word-form-heading;
					font-size: $word-form-heading-size;
					margin: 0;
				}
			}
/*

			.button-wrapper {
				display: flex;
				justify-content: space-between;
			
				.btn {
					font-size: $btn-font-size;
					border: none;
					border-radius: 15px;
					color: $btn-color;

					&.btn-save {
						background-color: $btn-save-background;
					}

					&.btn-cancel {
						background-color: $btn-cancel-background;
					}
				}
			}

			.word span {
				font-size: 14pt;
			}
			.word {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
			}
			.word .qrcode {
				padding: 5px;
			}
			.word .code {
				font-size: 2em;
				letter-spacing: 5pt;
				color: #09f;
				font-weight: bold;
				padding: 5px;
			}
			.original-def {
				background-color: #eee;
				padding: 5px;
				margin-bottom: 5px;
			}
			.word input {
				width: calc(100% - 10px);
				font-size: 14pt;
				color: #666;
				padding: 5px;
				border: 1px solid #999;
				border-radius: 5px;
				margin-bottom: 10px;
				outline: none;
			}
			.of-interest textarea, .notes textarea {
				font-size: 14pt;
				height: 120px;
				width: calc(100% - 10px);
				color: #666;
				padding: 5px;
				border: 1px solid #999;
				border-radius: 5px;
				outline: none;
			}
*/
		}
	}
}
